import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
//import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { FaSlidersH } from 'react-icons/fa';

const ClientList = () => {
    const data = useStaticQuery(graphql`
        query { 
            clients: allMarkdownRemark (
                filter: {frontmatter: {templateKey: {eq: "client"}}},
                sort: {fields: frontmatter___lastName order: ASC}
                ) {
                edges {
                node {
                    frontmatter {
                    fullName
                    portfolio_boolean
                    spotlight_cv
                    pdf_cv {
                        absolutePath
                    }
                    filters
                    thumbnail {
                        childImageSharp {
                        fluid(maxWidth:300, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        }
                    }
                    }
                    fields {
                        slug
                    }
                }

                }
                
            }
        }
    `)

    const [genderFilters, setGenderFilters] = useState('')
    const [typeFilters, setTypeFilters] = useState('')
    const [locationFilters, setLocationFilters] = useState('')
        
    const [clients, setClients] = useState(data.clients.edges)

    useEffect( () => {
        filterClients()
      },[genderFilters, typeFilters, locationFilters])

      const changeGenderFilters = (filter, e) => {
        e.currentTarget.classList.add('is-light', 'is-selected')
        const links = document.querySelectorAll('.filters-gender span');
        //find a more modern way than for loop
        for (let i = 0; i < links.length; i++) {
          if (links[i] === e.target) continue;
            links[i].classList.remove('is-light', 'is-selected');
        }
        setGenderFilters(filter)
      }
      const changeTypeFilters = (filter, e) => {
        e.currentTarget.classList.add('is-light', 'is-selected')
        const links = document.querySelectorAll('.filters-type span');
        //find a more modern way than for loop
        for (let i = 0; i < links.length; i++) {
          if (links[i] === e.target) continue;
            links[i].classList.remove('is-light', 'is-selected');
        }
        setTypeFilters(filter)
      }
      
  
      const filterClients = () => {
        let filteredClients = data.clients.edges
        if (genderFilters) {
         filteredClients = filteredClients.filter( (client) => {
            return client.node.frontmatter.filters.includes(genderFilters)
          })
        }
        if (typeFilters) {
          filteredClients = filteredClients.filter( (client) => {
             return client.node.frontmatter.filters.includes(typeFilters)
           })
         }
  
         setClients(filteredClients);
  
  
      }  
    
    return(
        <Layout>
        <SEO title="Latest News"/>
        <header className="clients-header section">
            <h1>Clients</h1>
            <nav>
            <div className="buttons has-addons filters">Filters <FaSlidersH /></div>
            {/*
            <div className="buttons has-addons filters-gender">
                <span className="button is-outlined  is-light is-selected" onClick={(e) => changeGenderFilters('', e)}>Both</span>
                <span className="button is-outlined" onClick={(e) => changeGenderFilters('male',e)}>Men</span>
                <span className="button is-outlined" onClick={(e) => changeGenderFilters('female',e)}>Women</span>
            </div>
            */}
            <div className="buttons has-addons filters-type">
                <span className="button is-outlined is-light is-selected" onClick={(e) => changeTypeFilters('', e)}>Both</span>
                <span className="button is-outlined" onClick={(e) => changeTypeFilters('actor', e)}>Actors</span>
                <span className="button is-outlined" onClick={(e) => changeTypeFilters('creative', e)}>Creatives</span>
            </div>
            
            
            </nav>
        </header>
        <section className="section">
            <div className="clients-list columns is-mobile is-multiline">
            
            
            {
                clients.map( (client) => {
                    const portfolio_boolean = client.node.frontmatter.portfolio_boolean
                    const slug = client.node.fields.slug
                    const spotlight = client.node.frontmatter.spotlight_cv
                    return (
                        <figure className="column is-half-mobile is-one-quarter-tablet is-one-fifth-desktop client-strip-item" key={client.node.frontmatter.fullName}>
                        { portfolio_boolean &&
                          <Link to={`/clients/${slug}`}>
                            {/*<Img fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}/>*/}
                            <BackgroundImage
                                tag="div"
                                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                                alt={client.node.frontmatter.fullName}
                                />
                            <figcaption>
                                {client.node.frontmatter.fullName}
                            </figcaption>
                          </Link>
                        }
                        {
                            !portfolio_boolean && spotlight &&
                            <a href={spotlight} target="_blank">
                            {/*<Img fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}/>*/}
                            <BackgroundImage
                                tag="div"
                                
                                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                                alt={client.node.frontmatter.fullName}
                                />
                            <figcaption>
                                {client.node.frontmatter.fullName}
                            </figcaption>
                            </a>
                        }
                        {
                            !portfolio_boolean && !spotlight &&
                            <div>
                            {/*<Img fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}/>*/}
                            <BackgroundImage
                                tag="div"
                                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                                alt={client.node.frontmatter.fullName}
                                />
                            <figcaption>
                                {client.node.frontmatter.fullName}
                            </figcaption>
                            </div>
                        }
                        
                        </figure>
                    )
                })
            }
            </div>
        </section>
        
        </Layout>
    )
}

export default ClientList